import {Inject, Injectable} from '@angular/core';
import {LocalStorage} from "../inject-token/local-storage";
import {BehaviorSubject} from "rxjs";

const BUS_PARTNER_COUNTRY_FAVORITES = "taxus-bus-partner-country-favorites";
const TRANSPORT_COUNTRY_FAVORITES = "taxus-transport-country-favorites"
const SEPARATOR = ",";

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private readonly busPartnerCountryFavorites$: BehaviorSubject<string[]>;
    private readonly transportCountryFavorites$: BehaviorSubject<string[]>;

    constructor(@Inject(LocalStorage) private storage: Storage) {
        this.busPartnerCountryFavorites$ = new BehaviorSubject(this.getCountryCodes(BUS_PARTNER_COUNTRY_FAVORITES));
        this.transportCountryFavorites$ = new BehaviorSubject(this.getCountryCodes(TRANSPORT_COUNTRY_FAVORITES));
    }

    get busPartnerCountryFavoritesSubject(): BehaviorSubject<string[]> {
        return this.busPartnerCountryFavorites$;
    }

    get transportCountryFavoritesSubject(): BehaviorSubject<string[]> {
        return this.transportCountryFavorites$;
    }

    setBusPartnerCountryFavorites(countryCodes: string[]) {
        this.setCountryCodes(BUS_PARTNER_COUNTRY_FAVORITES, countryCodes);
        this.busPartnerCountryFavorites$.next(countryCodes);
    }

    setTransportCountryFavorites(countryCodes: string[]) {
        this.setCountryCodes(TRANSPORT_COUNTRY_FAVORITES, countryCodes);
        this.transportCountryFavorites$.next(countryCodes);
    }

    clear(): void {
        this.busPartnerCountryFavoritesSubject.next([]);
        this.transportCountryFavoritesSubject.next([]);
        this.storage.clear();
    }

    private getCountryCodes(country: string) {
        return this.storage.getItem(country) !== null
            ? this.storage.getItem(country)!.split(SEPARATOR)
            : [];
    }

    private setCountryCodes(country: string, countryCodes: string[]) {
        this.storage.setItem(country, countryCodes.join(SEPARATOR));
    }
}
